<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the admin details</h4>
      <form autocomplete="off">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.firstName') && franchiseData.firstName!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.firstName') ? true : false)"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.firstName')"
                v-model="franchiseData.firstName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.lastName') && franchiseData.lastName!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.lastName') ? true : false)"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.lastName')"
                v-model="franchiseData.lastName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.email') && franchiseData.email!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.email') ? true : false)"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.email')"
                v-model="franchiseData.email"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.password') && franchiseData.password!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.password') ? true : false)"
                v-validate="'required'"
                name="password"
                data-vv-as="password"
                label="Password"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.password')"
                autocomplete="new-password"
                v-model="franchiseData.password"
                icon-no-border
                icon-pack="feather"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.contactNumber') && franchiseData.contactNumber!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.contactNumber') ? true : false)"
                v-validate="'required|max:150'"
                name="contactNumber"
                data-vv-as="contact number"
                label="Contact Number"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.contactNumber')"
                v-model="franchiseData.contactNumber"
              />
            </div>
          </vs-col>
          <vs-col vs-w="6" id="profileImageUpload" class="vs-con-loading__container">
            <div class="m-5">
              <label class="ml-1">Upload Profile Picture</label>

              <div class="mb-4" v-if="franchiseData.profileImage" style="margin:auto;width:35%;">
                <vs-avatar size="130px" align="center" :src="franchiseData.profileImage" />
              </div>

              <div style="text-align:center;position:relative;">
                <label
                  class="image-upload"
                  style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                >
                  Upload a profile photo
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadImage($event, 'profileImageUpload')"
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button
                size="normal"
                @click="saveData"
                icon-pack="feather"
                class="mr-2"
              >Create Admin</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import clinicViewEditDetail from "../../components/clinics/viewEditDetail.vue";
import { mapActions } from "vuex";
import axios from "@/axios";

export default {
  name: "clinicViewEditDetail",
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminClinic"
    }
  },
  data: () => ({
    franchiseData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      profileImage: ""
    },
    passwordType: "password",
    passwordIcon: "icon icon-eye-off"
  }),
  methods: {
    ...mapActions("franchise", ["createFranchiseAdmin"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let self = this;
      let user = self.$store.state.AppActiveUser.userRole;
      let isValid = await this.$validator.validateAll("basic");
      if (isValid) {
        await this.createFranchiseAdmin(this.franchiseData)
          .then(res => {
            self.$vs.notify({
              title: "Admin Created",
              text: "Admin created successfully.",
              color: "success"
            });
            self.$router.push("/franchise/admin");
          })
          .catch(err => {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to created admin. Try again!",
              color: "danger"
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
            if (err.status === 417) {
              self.errors.add({
                field: "email",
                msg: err.data.message,
                scope: "basic"
              });
            }
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function(value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
          scope: form
        });
      });
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then(res => {
          console.log(res.data.Location);
          self.franchiseData.profileImage = res.data.Location;

          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch(err => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger"
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger"
            });
          }
        });
    }
  }
};
</script>
